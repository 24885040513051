import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import LazyLoad from "react-lazyload";

import Layout from "../components/layout";
import HotelsSlider from "../components/hotels-slider";
import Map from "../components/map";
import Seo from "../components/seo";
import Pin from "../components/pin";

const HotelsTemplate = ({ data }) => {
  const { title } = data.contentfulPage;

  return (
    <Layout noButton>
      <Seo title={title} />
      <section className="section section-white pt-10">
        <div className="search">
          <div className="search-container">
            <h1 className="sr-only">{title}</h1>
            <div className="search-legend">
              <ul className="search-legend-list">
                <li className="search-legend-list-item">
                  <Pin />
                  <span>
                    ACE Hôtel <FormattedMessage id="simplement-bien" />
                  </span>
                </li>
                <li className="search-legend-list-item">
                  <Pin variant="travel" />
                  <span>ACE Hôtel Travel</span>
                </li>
              </ul>
            </div>
            <div className="search-map search-map-full">
              <Map />
            </div>
          </div>
        </div>
      </section>
      <section className="section section-blue overflow-hidden">
        <div className="container">
          <h2>
            <FormattedMessage id="all-hotels" />
          </h2>
          <div className="row mt-4 mt-md-8">
            <div className="col-8 col-sm-10 col-lg-12 position-static">
              <LazyLoad>
                <HotelsSlider />
              </LazyLoad>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default HotelsTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      meta_description
      hero_image {
        file {
          url
        }
      }
    }

    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      thumbnail {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allContentfulHotel(
      filter: { slug: { ne: "enseigne" }, node_locale: { eq: $locale } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        phone
        slug
        node_locale
        lat
        lon
        booking_id
        address
        id
        spirit {
          title
          slug
          logo {
            fluid(maxWidth: 100, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        hero_image {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        thumbnail {
          fluid(maxHeight: 320, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      totalCount
    }
  }
`;
